<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('mytask2[6]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="panel">
        <div style="width: 80%">
        <div style="font-size: 20px">{{$t('hall4[3]')}}</div>
        <div style="margin-top:25px;color: #ccc">{{$t('hall4[4]')}} {{infoData.match_number}} USDT</div>
        </div>
        <div>
          <img :src="`./static/icon/dd3.png`" style="height: 3.2rem"/>
        </div>
      </div>
            <div class="infoBox">
              <div style="margin-left: 15px">
                <span :style="infoData.task_type==2?'color:#F0B80D':'color:#0FCB81'">{{infoData.task_typestr}} USDT</span>
              </div>
              <van-field readonly
                         v-model="amount"
                         :label="$t('hall4[5]')"
              />
              <van-cell-group>
                <van-field readonly
                        v-model="dprice"
                        :label="$t('hall.list[1]')"
                />
                <van-field readonly
                           v-model="infoData.match_number"
                           :label="$t('hall.list[0]')"
                />
                <van-field readonly
                           v-model="infoData.order_number"
                           :label="$t('common6[1]')"
                />
                <van-field readonly
                           v-model="infoData.adddate"
                        :label="$t('mytask2[5]')"
                />
              </van-cell-group>
            </div>
          <div style="text-align: center;margin-top: 20px" v-show="showSubmit">
            <p class="btn" @click="onSubmit">{{ submitText }}</p>
          </div>
    </div>

  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: 'BindAccount',
  components: {

  },
  props: ['taskId'],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      infoData:{},
      BankInfo:{price:null,total_number:null,min_number:null,max_number:null},
      CoinInfo:{price:0,bestPrice:0},
      columns : ['ERC20', 'TRC20', 'OMNI'],
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      tabsList: [],
      submitText:"",
      isSubmit:true,
    }
  },
  computed: {
    dprice(){
      return parseFloat(this.infoData.price).toFixed(2)+" "+this.InitData.usdtinfo.sign;
    },
    balance(){
      return parseFloat(this.UserInfo.balance).toFixed(2)+' USDT'
    },
    amount(){
      var amm = parseFloat(this.infoData.price*this.infoData.match_number).toFixed(2)+" "+this.InitData.usdtinfo.sign;
      return amm;
    },
    state(){
      return this.$t('task.tabs['+this.infoData.status+']');
    },
    showSubmit(){
      let a = this.infoData.uid!=this.UserInfo.userid&&this.infoData.pay_state==0;
      let b = this.infoData.uid==this.UserInfo.userid&&this.infoData.pay_state==1;
      return this.isSubmit&&this.infoData.status==1&&(a||b);
    }
  },
  watch: {

  },
  created() {
    this.getTaskinfo();
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    getTaskinfo() {
      this.$Model.GetTaskOrderinfo(this.taskId, data => {
        this.isLoad = false;
        if (data.code == 1) {
          this.infoData = data.info;
          var task_type = this.infoData['task_type']
          var ordertype = this.infoData['ordertype']
          var typestr = this.$t('hall4[6]')
          if (task_type==2||ordertype==2)
            typestr = this.$t('hall4[7]')
          this.infoData['task_typestr'] = typestr;
          debugger
          if (this.infoData.pay_state==0){
            this.submitText = this.$t('withdraw2[4]');
          }else if (this.infoData.pay_state==1){
            this.submitText = this.$t('taskOrder2[2]');

          }
        }
      });
    },
    onSubmit() {
      let param = {id:this.taskId,status:3}
      this.$Model.FinishTask(param, data => {
        this.isSubmit = false;
        let msg = data.code_dec;
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break
          default:
            msg = this.$t('taskOrder2[3]');
            break
        }
        this.$Dialog.Toast(msg);
        this.$router.push("/myTask");
      });
    }
  }
}
</script>
<style scoped>
.PageBox {
  color: #635327;
  background-color: #13171A;
}

.van-cell>>>.van-cell__title{
  font-size: 14px;
}
.PageBox>>>.van-field__label {
  color: #e7e7e7;
}
.PageBox>>>.van-field__control {
    color: #e7e7e7;
}

.panel {
  display: flex;
  border-radius: 5px;
  padding: 20px;
  color:#e7e7e7;
  background-color: #191C23;
  margin-bottom: 10px;
}

.infoBox {
  width: 95%;
  padding: 10px 10px 20px 10px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #191C23;
  color: #e7e7e7;
}

.van-cell {
  background: #191C23;
}
.van-cell>>>.van-cell__title{
  font-size: 14px;
  color: #cccccc;
}

.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}

.btn {
  width: 90%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: #F5C73A;
  color: #000;
  font-size: 18px;
  text-align: center;
  margin: 15px 20px 30px;
}
</style>
